import React from "react";
import './b1_search_grades.css'
import LayoutB1 from "../../components/layout-b1"
import { navigate } from "@reach/router"

export default class B1SearchEnroll extends React.Component{
  toEnrollInfo=()=>{
    navigate('/demos/themeB1/race_enroll_info')
  }
  render() {
    return(
      <LayoutB1 location={this.props.location} >
        <div className="search-grades">
          <div className="search-grades-title">
            <img src={require('../../images/b1/query_results.svg')} alt="check"/>
            <div>查看报名</div>
          </div>
          <div className="search-race">
            <div className="race-select">
              <div className="select-title">赛事名称:</div>
              <div className="race-name">
                <select>
                  <option value="1">赛事一</option>
                  <option value="2">赛事二</option>
                  <option value="3">赛事三</option>
                </select>
              </div>

            </div>
            <div className="id-num-input">
              <div className="select-title">证件号码:</div>
              <div className="id-num">
                <input placeholder="请输入身份证号/护照/军官证/台胞证/回乡证"/>
              </div>
            </div>

          </div>

          <div className="search-confirm" onClick={this.toEnrollInfo}>确定</div>
        </div>
      </LayoutB1>
    )
  }
}